import {
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { AnimationItem } from 'lottie-web';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Subject,
  Subscription,
  take,
  takeUntil,
} from 'rxjs';
import { IFakeDetection, ProjectStatusEnum } from 'src/app/models/defines';
import { LottiePlayerService } from 'src/app/services/lottie-player.service';
import {
  IChunkCounterAndUploader,
  LocalRecorderService,
} from 'src/app/services/show/local-recorder.service';
import { ProfileService } from 'src/app/services/show/profile.service';
import {
  SnackbarActionEnum,
  SnackBarService,
} from 'src/app/services/utils/snack-bar.service';
import { FinishRecordingDialogComponent } from 'src/app/components/dialogs/finish-record-demo-dialog/finish-recording-dialog.component';
import { RecordingManagerService } from 'src/app/services/studio/recording-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtDirectorService } from '../../../../../services/art-director.service';
import { UserMediaSelectorComponent } from 'src/app/components/minor/user-media-selector/user-media-selector.component';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { NavListenersService } from '../../../../../services/nav-listeners.service';
import { IProject } from 'src/app/models/project-model';
import { IScene } from 'src/app/models/project/scene-model';
import { ITake, TakeStatusEnum } from 'src/app/models/project/take/take-model';
import { SceneConverterService } from 'src/app/services/project/convertors/scene-converter.service';
import { AspectRatioEnum } from '../../../../../models/project/edit/edit-model';
import { SceneSetupDialogComponent } from 'src/app/components/dialogs/scene-setup-dialog/scene-setup-dialog.component';
import { IDesign } from 'src/app/models/design.model';
import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { AnalyticsNotifierService } from 'src/app/services/utils/analytics-notifier.service';
import { NavigatorDevicesService } from 'src/app/services/recording/navigator-devices.service';
import { JoyrideService } from 'ngx-joyride';
import { UserService } from 'src/app/services/api/auth/user.service';
import { TeleprompterComponent } from '../../../../../components/major/teleprompter/teleprompter.component';
import { ConfirmationModalComponent } from 'src/app/components/dialogs/confirmation-modal/confirmation-modal.component';
import { IConfirmationModalData } from '../../../../../components/dialogs/confirmation-modal/confirmation-modal.component';
import {
  ISceneRecordingStatus,
  RecordingStatusManagerService,
} from 'src/app/services/state-management/project/recording-status-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PrompterSyncronizerService } from 'src/app/services/prompter-syncronizer.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

import { StudioProjectManagerService } from '../../../../../services/studio/studio-project-manager.service';
import {
  MissingMediaDeviceIdError,
  NoDevicePermissionsError,
} from 'src/app/models/errors/media-devices.errors';
import {
  StudioAnimations,
  StudioCarouselConfig,
} from './studio-animations-and-carousel';
import { StudioJoyRideEnum } from './studio-types';
import { STUDIO_PRODUCTION_ID_PARAM } from '../../../host/services/shared/constants/client/project/studio.routes';
import { EDIT_ROOM_URL } from '../../../host/services/shared/constants/client/project/edit-room.routes';
import { EditApiService } from 'src/app/services/api/auth/projects/edit-api.service';
import { EditManagerService } from 'src/app/services/show/project/edit-manager.service';
import { RecordingProgressService } from 'src/app/services/studio/recording-progress.service';
import { EnhanceManagerService } from '../../../../../services/project/enhance/enhance-manager.service';

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss'],
  animations: [StudioAnimations],
})
export class StudioComponent implements OnDestroy, OnInit {
  public static HIDE_JOYRIDE_LOCALSTORAGE_KEY = 'hide-studio-joyride';
  @ViewChild('slickCarousel', { static: false })
  slickCarousel: SlickCarouselComponent;
  isMobileUser: boolean;
  carouselSlideIndex = undefined;
  project: IProject;
  projectId: string;
  exportProjectModel: IProject;
  isLoadingPreview$ = new BehaviorSubject<boolean>(false);
  lottieAnimationItems = new Map<string, AnimationItem>();
  animationLoaded$ = new BehaviorSubject<boolean>(false);
  currentScene: IScene;
  currentRecordedTake: ITake;
  sceneHistory: IScene[] = [];
  pageIsReady$ = new BehaviorSubject<boolean>(false);
  loadedAssetsFinished$ =
    this.artDirector.loadedAssetsFinishedSubject.asObservable();
  onDestroy$ = new Subject();
  showExportBtn: boolean = false;
  didLottieRebranded$ = this.artDirector.allLottiesDynamicValuesAreReplaced$;
  finishedDevicesSetup$ = new BehaviorSubject<boolean>(false);
  StudioJoyRideEnum = StudioJoyRideEnum;
  storylineSliderConfig = StudioCarouselConfig;
  isUploadingLocalRecording: boolean;
  chunksOfLocalRecordingData: IChunkCounterAndUploader;
  percentagesToDisplayOfUploader: number;
  playOrPause$ = new BehaviorSubject<IFakeDetection>({
    randomNumber: Math.random(),
    isPlaying: false,
  });
  currentAspectRatio: AspectRatioEnum;
  AspectRatioEnum = AspectRatioEnum;
  public devicesPermissions$ = this.navigatorDevicesService.devicesPermissions$;
  joyRideSteps = [
    StudioJoyRideEnum.STORY_LINE,
    StudioJoyRideEnum.TELEPROMPTER,
    StudioJoyRideEnum.EDIT,
    StudioJoyRideEnum.START_RECORD,
  ];
  prompterDialogRef: MatDialogRef<TeleprompterComponent>;
  editDialogRef: MatDialogRef<SceneSetupDialogComponent>;
  currentSceneIndex: number;
  sceneStatuses: ISceneRecordingStatus[];
  freezeAtFirstFrame = true;

  protected readonly TakeStatusEnum = TakeStatusEnum;
  protected readonly ProjectStatus = ProjectStatusEnum;

  constructor(
    private editMangaerService: EditManagerService,
    private editApiService: EditApiService,
    private studioProjectManager: StudioProjectManagerService,
    public recordingStatusManager: RecordingStatusManagerService,
    public recordingManager: RecordingManagerService,
    public navListener: NavListenersService,
    private dialog: MatDialog,
    private artDirector: ArtDirectorService,
    private profileService: ProfileService,
    private lottiePlayer: LottiePlayerService,
    private projectApiService: ProjectAuthApiService,
    private ngZone: NgZone,
    private snackBarService: SnackBarService,
    public profile: ProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private sceneConvertor: SceneConverterService,
    private localRecorderService: LocalRecorderService,
    private projectStoreService: ProjectStoreService,
    private analyticsNotifierService: AnalyticsNotifierService,
    private joyrideService: JoyrideService,
    private userService: UserService,
    private navigatorDevicesService: NavigatorDevicesService,
    private prompterSync: PrompterSyncronizerService,
    private sanitizer: DomSanitizer,
    public recordingProgressService: RecordingProgressService,
    private enhanceManager: EnhanceManagerService
  ) {
    let didRecord: boolean = false;
    const didRecordBeforeKey = this.profileService.didRecordLocalStorageKey;

    /// the key might not be existed so it will fail to json parse.
    try {
      didRecord = JSON.parse(localStorage.getItem(didRecordBeforeKey));
    } catch (error) {
      didRecord = false;
    }

    recordingStatusManager.sceneStatusUpdtes$.subscribe((sceneStatuses) => {
      if (sceneStatuses) {
        this.sceneStatuses = sceneStatuses;
      }
    });
  }

  /**
   * Initializes the studio component.
   */
  ngOnInit(): void {
    this.initJoyRideLocalStorage();
    this.checkMobileUser();
    this.subscribeToPageIsReady();
    this.getProjectIdByRouteParams();
    this.subscribeToDevicesPermissions();
    this.subscribeToStudioManager();
    this.initMediaDevicesAsync();
    this.initExtendedProjectModelAsync(this.projectId, false); /// Todo: I'm pretty sure we need to wait for the custom layouts before this
    this.subscribeToWantToStartRecord();
    this.subscribeToLocalRecorder();
    this.finishedDevicesSetup$.subscribe((loaded) => {
      if (loaded) {
        setTimeout(() => {
          this.navListener.isSideNavOpen.next(false);
        }, 500);
      }
    });
  }

  subscribeToPageIsReady() {
    this.pageIsReady$.subscribe((isReady) => {
      if (isReady) {
        setTimeout(() => {
          this.goToSpecificSceneIndex(0);
        }, 500);
      }
    });
  }

  trackBySceneId(index: number, scene: IScene): string {
    return scene.id;
  }

  joyRideStepChanged(step: string): void {
    switch (step) {
      case StudioJoyRideEnum.STORY_LINE:
        break;
      case StudioJoyRideEnum.TELEPROMPTER:
        this.togglePrompter();
        break;
      case StudioJoyRideEnum.EDIT:
        this.prompterDialogRef.close();

        setTimeout(() => {
          this.openSceneSetupDialog(
            this.currentScene,
            this.project.designGroup.design
          );
        }, 1000);
        break;
      case StudioJoyRideEnum.START_RECORD:
        this.editDialogRef.close();
        break;
    }
  }

  goToSpecificSceneIndex(index: number) {
    const currentSceneCount = this.project?.scenes?.length ?? 0;

    if (index >= 0 && index < currentSceneCount) {
      if (this.slickCarousel) {
        this.slickCarousel.slickGoTo(index);
      }
    }
  }

  /**
   * This should be called only by the carousel events!!
   * Will choose a scene when it was clicked.
   * @param scene
   * @private
   */
  private chooseScene(scene: IScene | null) {
    if (!scene) {
      return;
    }

    if (scene.chosenTake.lottieComposedConfigs) {
      this.isLoadingPreview$.next(true);
    }

    if (scene) {
      this.currentSceneIndex = this.exportProjectModel.scenes.findIndex(
        (projectScene) => projectScene.id === scene.id
      );
    }
    this.studioProjectManager.setCurrentSceneById(scene.id);
  }

  public playLottieAnimation(lottieId: string) {
    const animationItem = this.lottieAnimationItems.get(lottieId);
    this.lottiePlayer.lottieFindMarksAndPlay(animationItem, this.ngZone);
  }

  /**
   * Initiates the scene to be shot and triggers the countdown for recording.
   * This function is asynchronous and returns a promise that resolves when the countdown is triggered.
   *
   * @async
   * @returns {Promise<void>} A promise that resolves when the countdown is triggered.
   */
  public async recordButtonClicked(): Promise<void> {
    const success = await this.recordingManager.recordButtonClickedAsync();
    if (!success) {
      // If the media stream doesn't exist, set the current scene and take to null
      this.setCurrentSceneAndTakeToNull();
      console.warn(
        'No stream yet, please try again when there is a stream to record.'
      );
      this.snackBarService.openMessage(
        'No stream yet, please try again when there is a stream to record.',
        SnackbarActionEnum.Dismiss,
        5000
      );
    }
  }

  public async haveAnotherTakeButtonClicked() {
    const basePath = this.project.designGroup.design.basePath;

    // Add another take to the scene
    await this.recordingManager.addTakeToSceneAsync(
      this.project,
      this.currentScene,
      this.currentScene.chosenTake.copy.dynamicLottieChanges,
      basePath
    );

    /// Start the recording
    this.recordButtonClicked();
  }

  gotToProjectPlanning() {
    this.router.navigate([
      'dashboard',
      { outlets: { panel: ['project', this.projectId] } },
    ]);
  }

  public goToEditRoom() {
    this.router.navigate([
      'dashboard',
      { outlets: { panel: [EDIT_ROOM_URL, this.project.id] } },
    ]);
  }

  @HostListener('document:keydown.space', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.stopRecordingButtonClickedAsync(false);
  }

  public async stopRecordingButtonClickedAsync(moveToNextScene: boolean) {
    if (this.currentScene?.chosenTake.lottieComposedConfigs) {
      this.isLoadingPreview$.next(true);
    }

    await this.recordingManager.finishTakeAsync();
    if (moveToNextScene) {
      this.goToSpecificSceneIndex(this.currentSceneIndex + 1);
    }
  }

  public async ExportAsync() {
    const scenesLeftToRecord = this.recordingStatusManager.scenesLeftToRecord;
    if (scenesLeftToRecord.length > 0) {
      console.log(`OPEN DIALOG`);

      const confirmationDialogData: IConfirmationModalData = {
        title: {
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(
            `Whoops! You still have ${scenesLeftToRecord.length} scene to record!`
          ),
          align: 'center',
        },
        body: {
          htmlContent: this.sanitizer.bypassSecurityTrustHtml(
            `You're Almost There! 💪 <br> Don't miss out on capturing all the fun!`
          ),
          align: 'center',
        },
        buttons: [
          {
            text: `Exclude these scene${scenesLeftToRecord.length > 1 ? 's' : ''}`,
            css: {
              color: 'white',
              style: {},
            },
            callback: async () => {
              await this.exportDialogAsync();
            },
          },
          {
            text: 'Take me there',
            css: {
              color: 'primary',
              style: {},
            },
            callback: () => {
              const firstSceneToRecord =
                this.recordingStatusManager.allSceneStatuses.findIndex(
                  (sceneStatus) => {
                    return sceneStatus.leftToRecord;
                  }
                );

              if (firstSceneToRecord < 0) {
                /// Something happend......
                return;
              }
              this.goToSpecificSceneIndex(firstSceneToRecord);
            },
          },
        ],
      };
      this.dialog.open(ConfirmationModalComponent, {
        width: '550px',

        data: confirmationDialogData,
      });
      return;
    }

    await this.exportDialogAsync();
  }

  public async toggleMuteMicClicked() {
    this.recordingManager.toggleMicMute();
  }

  public togglePrompter() {
    if (
      !this.prompterDialogRef ||
      this.prompterDialogRef.getState() === MatDialogState.CLOSED
    ) {
      this.prompterDialogRef = this.dialog.open(TeleprompterComponent, {
        height: '300px',
        width: '500px',
        maxWidth: '90vw',
        maxHeight: '90vh',
        hasBackdrop: false,
        panelClass: 'teleprompter-dialog',
      });
    } else {
      this.prompterDialogRef.close();
      this.prompterDialogRef = null;
    }
  }

  teleprompterTextChanged(newText: string) {
    const scene = this.currentScene;
    this.studioProjectManager.updateSceneTeleprompterAsync(newText, scene);
  }

  public async openSceneSetupDialog(scene: IScene, design: IDesign) {
    this.editDialogRef = this.dialog.open(SceneSetupDialogComponent, {
      data: { scene: scene, design: design, project: this.project },
      minWidth: '300px',
      minHeight: '350px',
      width: '600px',
    });
    this.editDialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return;
      }
      scene.chosenTake.copy = res.takeChanges;
      await this.recordingManager.updateTakeSetupAsync(scene);
    });
  }

  slickAfterChange(e) {
    console.log('slickAfterChange', e);
    // if(currentSlide )
    // goToSpecificSceneIndex
    if (this.carouselSlideIndex !== e.currentSlide) {
      console.log('Slick Navigated to ', e.currentSlide);
      this.carouselSlideIndex = e.currentSlide;
      this.chooseScene(this.project.scenes[this.carouselSlideIndex]);
    }
  }

  public freezedAtFirstFrame() {
    this.freezeAtFirstFrame = false;
  }

  ngOnDestroy(): void {
    if (this.prompterDialogRef) {
      this.prompterDialogRef.close();
    }
    this.cleanupAsync().then(() => {
      this.recordingManager.onDestroy$.next(true);
      this.onDestroy$.next(true);
      this.onDestroy$.complete();
    });
  }

  // public enhance() {
  //   if (
  //     !this.currentRecordedTake.videoLayers ||
  //     this.currentRecordedTake.videoLayers.length === 0
  //   ) {
  //     this.snackBarService.openMessage(
  //       `Could not enhance because no video layers are existed`,
  //       SnackbarActionEnum.Close,
  //       5000
  //     );
  //     return;
  //   }

  //   this.enhanceManager.enhanceVideoLayersAsync(
  //     this.projectId,
  //     this.currentScene.id,
  //     this.currentRecordedTake.id,
  //     this.currentRecordedTake.videoLayers.map((videoLayer) => videoLayer.id)
  //   );
  // }

  /**
   * Initializes the JoyRide local storage key if it doesn't exist.
   */
  private initJoyRideLocalStorage(): void {
    if (!localStorage.getItem(StudioComponent.HIDE_JOYRIDE_LOCALSTORAGE_KEY)) {
      localStorage.setItem(
        StudioComponent.HIDE_JOYRIDE_LOCALSTORAGE_KEY,
        'false'
      );
    }
  }

  private checkMobileUser(): void {
    this.isMobileUser = this.userService.isMobileUser();
  }

  private getProjectIdByRouteParams(): void {
    this.projectId = this.route.snapshot.params[STUDIO_PRODUCTION_ID_PARAM];
  }

  private subscribeToDevicesPermissions(): void {
    this.devicesPermissions$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAllowed) => {
        if (!isAllowed) {
          this.stopRecordingButtonClickedAsync(false);
          // this.dialog
          //   .open(UserMediaSelectorComponent, {
          //     width: '450px',

          //     data: {
          //       displayClose: true,
          //       title: "Let's set up the Mic and Camera",
          //     },
          //   })
          // .afterClosed();
        }
      });
  }

  private subscribeToStudioManager(): void {
    /// It's project$ from studio manager
    this.studioProjectManager.project$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((project) => {
        if (!project) {
          return;
        }

        this.project = project;
      });

    this.studioProjectManager.currentScene$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((currentScene) => {
        if (!currentScene) {
          return;
        }

        this.currentScene = currentScene;
      });

    this.studioProjectManager.currentTake$.subscribe((currentTake) => {
      if (!currentTake) {
        return;
      }

      this.currentRecordedTake = currentTake;
      this.freezeAtFirstFrame = true;
    });
  }

  private subscribeToWantToStartRecord(): void {
    // this.startRecordingCountdown$
    //     .pipe(takeUntil(this.onDestroy$))
    //     .subscribe((wantToStartRecording) => {
    //         if (wantToStartRecording) {
    //             this.setSceneActionsAsDisable();
    //         }
    //     });
  }

  private async exportDialogAsync() {
    this.projectApiService.isLoadingExportData$.next(true);
    await this.buildExportJobDataAsync();
    const dialogSubscription = this.dialog.open(
      FinishRecordingDialogComponent,
      {
        width: '550px',
        minHeight: '400px',
        maxHeight: '90vh',
        disableClose: true,
      }
    );

    dialogSubscription.afterClosed().subscribe((closedData) => {
      this.router.navigate(['dashboard', { outlets: { panel: ['library'] } }]);
    });
  }

  private startJoyrideTour() {
    const studioJoyRideStepSubscriber = this.joyrideService.startTour({
      steps: this.joyRideSteps,
      themeColor: '#5E337BFF',
      showPrevButton: false,
      waitingTime: 1000,
    });

    // studioJoyRideStepSubscriber.subscribe(
    //   (step) => this.joyRideStepChanged(step.name),
    //   (err) => console.error(err),
    //   () => (this.selectedTabIndex = 1)
    // );
    localStorage.setItem(StudioComponent.HIDE_JOYRIDE_LOCALSTORAGE_KEY, 'true');
  }

  private async initMediaDevicesAsync() {
    console.log('initMediaDevicesAsync');
    try {
      await this.recordingManager.updateMediaStreamAsync();
      console.log('finishedDevicesSetup', this.finishedDevicesSetup$.value);
      this.finishedDevicesSetup$.next(true);
    } catch (error) {
      if (
        error instanceof NoDevicePermissionsError ||
        error instanceof MissingMediaDeviceIdError ||
        error.name === 'NotAllowedError'
      ) {
        console.log('Probably no permissions, opening the setup dialog...');
        this.dialog
          .open(UserMediaSelectorComponent, {
            width: '450px',

            data: {
              displayClose: true,
              title: "Let's set up the Mic and Camera",
            },
          })
          .afterClosed()
          .subscribe(async () => {
            this.finishedDevicesSetup$.next(true);
            // await this.recordingManager.updateMediaStreamAsync();
          });
      }
    }
  }

  private async cleanupAsync() {
    try {
      await this.recordingManager.finishTakeAsync();
      this.recordingManager.stopMediaStream();
    } catch (error) {
      console.error(`An error occurred!!!`, error);
      this.snackBarService.openMessage(
        `An error occurred while finishing take.`,
        SnackbarActionEnum.Close,
        5000
      );
    }
  }

  private setCurrentSceneAndTakeToNull() {
    this.studioProjectManager.setCurrentSceneById(null);
  }

  private async buildExportJobDataAsync() {
    // const editOutDTO = this.getExportEditOutDTO();
    const editOutDTO = this.editMangaerService.buildEditJobOutDTO(
      this.project,
      this.currentAspectRatio
    );
    this.editApiService.postEdit$(editOutDTO, false).subscribe((res) => {
      this.projectApiService.isLoadingExportData$.next(false);
      const data = {
        userEmail: this.project.user?.email,
        prompt: this.project.creative.prompt,
        editAt: new Date(),
        projectCreatedAt: this.project.createdAt,
      };
      this.analyticsNotifierService.notifyEvent(`Export`, data);
      console.log(`Exporting! ${res}`);
    });
  }

  private async initExtendedProjectModelAsync(
    projectId: string,
    waitForLocalVideos: boolean
  ) {
    this.pageIsReady$.next(false);
    /// We know it will trigger project$ if succeded
    this.studioProjectManager.getProjectDataAsync(
      projectId,
      waitForLocalVideos
    );
    const combineSubjects = combineLatest([
      this.didLottieRebranded$,
      this.projectApiService.customLayouts$,
      this.recordingManager.mediaModel$,
      this.studioProjectManager.project$,
      this.finishedDevicesSetup$,
    ]);

    combineSubjects
      .pipe(
        takeUntil(this.onDestroy$),
        filter(
          ([
            areLottiesReady,
            customLayouts,
            streamsToDisplay,
            project,
            finishedDeviceSetup,
          ]): boolean => {
            return !!(
              project &&
              areLottiesReady &&
              customLayouts &&
              streamsToDisplay &&
              finishedDeviceSetup
            );
          }
        ),
        take(1),
        map(
          async ([
            areLottiesReady,
            customLayouts,
            streamsToDisplay,
            project,
          ]) => {
            // This will happen only once, when all the values are fulfilled
            this.pageIsReady$.next(true);

            const scenes: IScene[] = await Promise.all(
              this.project.scenes.map((inScene, index) => {
                return this.sceneConvertor.inToLocalAsync(
                  inScene,
                  this.project.designGroup.design.basePath,
                  waitForLocalVideos,
                  this.project.id,
                  this.project.indexDBData,
                  streamsToDisplay.id
                );
              })
            );

            /// Replacing the existing ones because now we have stream id!
            /// (for an example, in project planning we don't have)
            this.projectStoreService.replaceOrAddProjectScenes(
              this.project.id,
              scenes,
              0
            );

            this.recordingManager.setScenes(this.project.scenes);

            this.exportProjectModel = this.project;

            this.currentAspectRatio =
              this.project.designGroup.design.aspectRatio ??
              AspectRatioEnum._16x9;
            this.goToSpecificSceneIndex(0);

            const toDisplayJoyride =
              localStorage.getItem(
                StudioComponent.HIDE_JOYRIDE_LOCALSTORAGE_KEY
              ) === 'false' && !this.isMobileUser;
            let joyrideSubscription: Subscription | null = null;

            if (toDisplayJoyride) {
              joyrideSubscription = this.finishedDevicesSetup$
                .pipe()
                .subscribe({
                  next: (res) => {
                    if (!res) {
                      return;
                    }
                    setTimeout(() => {
                      this.startJoyrideTour();
                      if (joyrideSubscription) {
                        joyrideSubscription.unsubscribe();
                      }
                    }, 1000);
                  },
                });
            }
          }
        )
      )
      .subscribe();
  }

  private subscribeToLocalRecorder() {
    this.localRecorderService.isUploading$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isUploading) => {
        this.isUploadingLocalRecording = isUploading;
      });

    this.localRecorderService.recordingChunksCounter$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((chunksData) => {
        this.chunksOfLocalRecordingData = chunksData;
        if (!chunksData) {
          return;
        }

        let percentages: number = chunksData.howMuchCompletedInPercentages;
        if (!this.showExportBtn) {
          percentages = Math.min(percentages, 99);
        }
        this.percentagesToDisplayOfUploader = percentages;
      });

    const combineSubjects = combineLatest([
      this.recordingProgressService.isRecordingInProgress$,
      this.localRecorderService.isAnyMediaRecorderActive$,
      this.localRecorderService.isUploading$,
    ]);

    combineSubjects
      .pipe(
        takeUntil(this.onDestroy$),
        map(([wantToStartRecord, anyRecorderActive, isUploadingData]) => {
          const toDsiplayExportButton =
            !wantToStartRecord && !anyRecorderActive && !isUploadingData;
          // &&
          // !!this.selectedLocalRecordingToExport;
          //temp solution to know that we already recorded at least 1 take.
          // &&
          // this.localRecordings?.length >= 0;

          this.showExportBtn = toDsiplayExportButton;
        })
      )
      .subscribe();
  }
}
