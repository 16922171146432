<div
  class="chips"
  [class.auto-hide]="showIsRecordingChip || showIsPreviewChip ? false : hidden">
  <mat-chip *ngIf="showIsHiddenChip" class="chip"> Excluded </mat-chip>
  <mat-chip
    *ngIf="showIsLiveChip && !showIsRecordingChip && this.autoHide === false"
    class="chip">
    <mat-icon>linked_camera</mat-icon>
    Live Mode
  </mat-chip>
  <mat-chip *ngIf="showIsPreviewChip" class="chip">
    <mat-icon>play_circle_outline</mat-icon>
    Preview Mode
  </mat-chip>

  <mat-chip *ngIf="showIsRecordingChip" class="chip">
    <app-timer
      [timeInSeconds]="
        recordingProgressService.recordingProgress$ | async
      "></app-timer>
  </mat-chip>
</div>
