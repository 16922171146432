import { AfterViewInit, Component, Input } from '@angular/core';
import { RecordingManagerService } from 'src/app/services/studio/recording-manager.service';
import { RecordingProgressService } from '../../../../../../services/studio/recording-progress.service';

@Component({
  selector: 'studio-scene-chips',
  templateUrl: './studio-scene-chips.component.html',
  styleUrls: ['./studio-scene-chips.component.scss'],
})
export class StudioSceneChipsComponent implements AfterViewInit {
  @Input('showIsHiddenChip') showIsHiddenChip = false;
  @Input('showIsLiveChip') showIsLiveChip = false;
  @Input('showIsPreviewChip') showIsPreviewChip = false;
  @Input('showIsRecordingChip') showIsRecordingChip = false;

  @Input('autoHide') autoHide = false;
  hidden = false;

  constructor(public recordingProgressService: RecordingProgressService) {}

  ngAfterViewInit(): void {
    if (this.autoHide) {
      setTimeout(() => {
        this.hidden = true;
      }, 3000);
    }
  }
}
