import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  lastValueFrom,
  map,
  Subject,
  take,
  takeUntil,
} from 'rxjs';
import {
  IBasicLottieLayer,
  IDynamicLottieData,
} from 'src/app/models/lottie/lottie-defines';
import {
  ChunksUploadTypeEnum,
  INewChunksDocumentParams,
} from '../api/chunks-uploader-api.service';
import { TakeApiService } from '../api/auth/projects/take-api.service';
import {
  IProject,
  IVideoSegmentProperties,
} from 'src/app/models/project-model';
import { IScene, ISceneInDTO } from 'src/app/models/project/scene-model';
import {
  ITake,
  ITakeInDTO,
  ITakeUpdate,
  TakeStatusEnum,
  TakeUpdateableProperties,
} from 'src/app/models/project/take/take-model';
import { TakeConverterService } from '../project/convertors/take/take-converter.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IllegalArgumentException,
  MissingArgumentsError,
} from 'src/app/models/errors/general.errors';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';
import { ProjectStoreService } from '../state-management/project/project-store.service';
import { TakeNotFoundError } from 'src/app/models/errors/project-errors/takes-errors';
import { IMediaModel } from '../../pages/private/dashboard/project/studio/studio-types';
import { MediaDevicesService } from '../recording/media-devices.service';
import { ProfileService } from '../show/profile.service';
import { LocalRecorderService } from '../show/local-recorder.service';
import { AnalyticsNotifierService } from '../utils/analytics-notifier.service';
import { StudioProjectManagerService } from './studio-project-manager.service';
import { ArtDirectorService } from '../art-director.service';
import { ProjectAuthApiService } from '../api/auth/project-auth-api.service';
import { PrompterSyncronizerService } from '../prompter-syncronizer.service';
import { VideoConvertorService } from '../project/convertors/take/layers/video-convertor.service';
import { FunctionsHelperService } from '../functions-helper.service';
import { RecordingProgressService } from './recording-progress.service';

@Injectable({
  providedIn: 'root',
})
export class RecordingManagerService {
  // Key -> Scene id, Value -> All the takes under this scene
  public takesMap = new Map<string, ITake[]>();
  mediaModel$ = new BehaviorSubject<IMediaModel>(null);
  public onDestroy$ = new Subject();
  recordingTimer: any = null;
  recordingTimeInSeconds: number = 0;
  public recordingTimeInSeconds$ = new BehaviorSubject<number>(0);

  // To know if the api call for appending the take to scene in server is currently working
  private isAppendingTakeToSceneSubject = new BehaviorSubject<boolean>(false);
  public isAppendingTakeToScene$ =
    this.isAppendingTakeToSceneSubject.asObservable();

  private isUpdatingTakeSubject = new BehaviorSubject<boolean>(false);
  public isUpdatingTake$ = this.isUpdatingTakeSubject.asObservable();

  private isCoundownInProgressSubject = new BehaviorSubject<boolean>(false);
  public isCountdownInProgress$ =
    this.isCoundownInProgressSubject.asObservable();

  private project: IProject;
  private currentScene: IScene;
  private currentTake: ITake;
  private currentStagePositionId: string;

  constructor(
    private projectStoreService: ProjectStoreService,
    private studioProjectManager: StudioProjectManagerService,
    private takeApiService: TakeApiService,
    private takeConvertor: TakeConverterService,
    private mediaDevicesService: MediaDevicesService,
    private localRecorderService: LocalRecorderService,
    private analyticsNotifierService: AnalyticsNotifierService,
    public profileService: ProfileService,
    private artDirector: ArtDirectorService,
    private projectApiService: ProjectAuthApiService,
    private promptSync: PrompterSyncronizerService,
    private videoLayerConvertor: VideoConvertorService,
    private functionsHelper: FunctionsHelperService,
    private recordingProgressService: RecordingProgressService
  ) {
    this.subscribeToOnDestroy();
    this.subscribeToMediaDeviceChanges();
    this.subscribeToProjectManagerChanges();
    this.recordingProgressService.isRecordingInProgress$.subscribe(
      (isRecordingInProgress) => {
        const timeout = isRecordingInProgress ? 500 : 0;
        setTimeout(() => {
          this.promptSync.setRequestToStartTeleprompt(isRecordingInProgress);
        }, timeout);
      }
    );
  }

  public stopMediaStream() {
    const mediaStream = this.mediaModel$.value?.mediaStream;

    if (!mediaStream) {
      return;
    }

    // Stop the media stream
    mediaStream.getTracks().forEach((track) => track.stop());
    this.mediaModel$.next(null);
  }

  subscribeToOnDestroy() {
    // Stop the media stream on destroy
    this.onDestroy$.subscribe((destroy) => {
      if (destroy) {
        if (this.mediaModel$?.value?.mediaStream) {
          this.mediaModel$.value.mediaStream
            .getVideoTracks()
            .forEach((track) => {
              track.stop();
            });
        }
      }
    });
  }

  subscribeToProjectManagerChanges() {
    this.studioProjectManager.project$.subscribe((project) => {
      if (!project) {
        return;
      }
      this.project = project;
    });

    this.studioProjectManager.currentScene$.subscribe((currentScene) => {
      if (!currentScene) {
        return;
      }
      this.currentScene = currentScene;
      this.promptSync.setCurrentPromptText(currentScene.copy.script);
      /// Currently by default we take the first stage position to be our stage position id,
      /// When we are able to choose, we will use the set function
      /// If we don't have stage positions because the array is empty (graphic scene),
      /// It will be null :)
      this.stagePositionId = currentScene.stagePositions[0]?.id;
    });

    this.studioProjectManager.currentTake$.subscribe((currentTake) => {
      if (!currentTake) {
        return;
      }
      this.currentTake = currentTake;
    });
  }

  public addTakeToSceneAsync(
    project: IProject,
    scene: IScene,
    dynamicLottieChanges: IDynamicLottieChange[],
    baseDesignPath: string
  ) {
    if (!project || !scene || !dynamicLottieChanges || !baseDesignPath) {
      throw new Error(
        `Could not add take to scene because one of the arguments is null`
      );
    }
    const sceneId = scene.id;
    const projectId = project.id;
    const streamId = this.mediaModel$?.value.id;

    this.isAppendingTakeToSceneSubject.next(true);
    return new Promise<void>((resolve, reject) => {
      this.takeApiService
        .addTakeToScene$(projectId, sceneId, dynamicLottieChanges, true)
        .subscribe({
          next: async (insertedTake) => {
            if (!insertedTake) {
              console.error(`Could not add take to scene!`);
              ///TODO: Handle error
              return;
            }
            const basicLottieLayers: IBasicLottieLayer[] =
              scene.composition.layouts.map((layout) => {
                const basicLottieLayer: IBasicLottieLayer = {
                  lottieId: layout._id,
                  lottieJsonPath: layout.lottiePath,
                };
                return basicLottieLayer;
              });
            await this.setTakeInProjectStore(
              insertedTake,
              basicLottieLayers,
              scene,
              baseDesignPath,
              project,
              sceneId,
              streamId
            );
            return resolve();
          },
          error: (error) => {
            console.error(`ERROR ${error}`);
          },
        });
    });
  }

  public async addVideoLayerToTakeAsync(
    project: IProject,
    scene: IScene,
    take: ITake
  ) {
    const objectWithLowestPosition = scene.stagePositions.reduce(
      (prev, curr) => {
        return curr.position < prev.position ? curr : prev;
      }
    );
    /// Currently we send the first stage position in the array, when we will have 2 stage positions we will need to send which stage position we want :)
    const inVideoLayer = await take.addVideoLayerToTakeAsync(
      objectWithLowestPosition.id
    );
    const localVideoLayer = await this.videoLayerConvertor.inToLocalAsync(
      inVideoLayer,
      take,
      this.project.id
    );

    take.videoLayers = [localVideoLayer];
    this.addOrReplaceTake(project, scene, take);
  }

  private async setTakeInProjectStore(
    insertedTake: ITakeInDTO,
    basicLottieLayers: IBasicLottieLayer[],
    scene: IScene,
    baseDesignPath: string,
    project: IProject,
    sceneId: string,
    streamId: string
  ) {
    const localTake = await this.takeConvertor.inToLocalAsync(
      insertedTake,
      basicLottieLayers,
      scene.composition.layouts[0],
      baseDesignPath,
      this.mediaModel$.value?.id,
      false,
      project.id,
      scene.id,
      scene.name,
      project.indexDBData,
      streamId
    );
    if (!this.takesMap.has(sceneId)) {
      this.setScenes([scene]);
    }

    scene.takes.push(localTake);
    // We know that in our db we updated to chosen take so it's fine to do that
    scene.selectedTakeId = localTake.id;
    scene.chosenTake = localTake;
    this.projectStoreService.replaceOrAddProjectTakes(
      this.project.id,
      scene.id,
      localTake
    );

    this.isAppendingTakeToSceneSubject.next(false);
  }

  /**
   *
   * @param scenes
   * Should be used once per chosen format at a time.
   * When used, clearing all current states about scenes in service
   */
  public setScenes(scenes: IScene[]) {
    if (!scenes) {
      throw new Error(`Could not set scenes because scenes are null.`);
    }
    scenes.forEach((scene) => {
      this.takesMap.set(scene.id, scene.takes);
    });
  }

  public getTake(scene: IScene, takeId: string) {
    const takes = scene?.takes;

    const updateTake = takes?.find((take) => take.id === takeId);
    if (!updateTake) {
      throw new TakeNotFoundError(
        `Could not update take because take with takeid: ${takeId} is not found`
      );
    }
    return updateTake;
  }

  public getChunksDocumentData(
    additionalParams: IVideoSegmentProperties
  ): INewChunksDocumentParams {
    if (!additionalParams) {
      throw new Error(
        `Could not get chunks document additional data because it's null`
      );
    }
    const uploadType = ChunksUploadTypeEnum.RECORDING;
    return { uploadType: uploadType, additionalParams: additionalParams };
  }

  /**
   *
   * @param projectId
   * @param scene
   * @param takeId
   * @param propertiesToUpdate
   * @returns
   */
  public updateTakePropertyAsync<K extends keyof TakeUpdateableProperties>(
    project: IProject,
    scene: IScene,
    takeId: string,
    streamId: string,
    baseDesignPath: string,
    propertiesToUpdate: ITakeUpdate<K>[],
    waitForLocalVideos: boolean
  ) {
    if (!project || !scene || !takeId || !propertiesToUpdate) {
      throw new MissingArgumentsError(
        `Could not update demo take because one of the arguments is null or undefined.`
      );
    }
    if (propertiesToUpdate.length === 0) {
      return;
    }

    const takeToUpdate = this.getTake(scene, takeId);
    if (!takeToUpdate) {
      throw new Error(`Take with ID ${takeId} not found in scene ${scene}.`);
    }
    return new Promise<boolean>((resolve, reject) => {
      this.takeApiService
        .updateTake$(project.id, scene.id, takeId, propertiesToUpdate)
        .subscribe({
          next: async (inTake) => {
            if (!inTake) {
              console.error(
                `Something strange happened while trying to update take.`
              );
              return resolve(false);
            }

            await this.replaceOrAddTakeAsync(
              project,
              scene,
              inTake,
              streamId,
              baseDesignPath,
              waitForLocalVideos
            );
            // const localTakeProperties = Object.keys(takeToUpdate);
            // for (const updateKeyValue of propertiesToUpdate) {
            //   const key = updateKeyValue.key as string;
            //   /// We have to make sure that the properties we update are existed in our local take
            //   if (localTakeProperties.includes(key)) {
            //     takeToUpdate[key] = updateKeyValue.value;
            //   }
            // }
            return resolve(true);
          },
          error(err: HttpErrorResponse) {
            if (err.status === 500) {
              //TODO: try again
            }
          },
        });
    });
    // updateTake[propertyName] = value;
  }

  public sendTakeVideoLayersToDash(
    projectId: string,
    sceneId: string,
    take: ITake
  ) {
    if (!projectId || !sceneId || !take) {
      throw new MissingArgumentsError(
        `Could not send take video layers to dash because on of the arguments is null`
      );
    }
  }

  async recordButtonClickedAsync() {
    // Check if recording has already started
    if (this.recordingProgressService.getRecordingInProgressValue()) {
      // If recording has started, finish the current take
      try {
        await this.finishTakeAsync();
      } catch (e) {
        console.log(e);
      }
    }

    // Get the media stream from the media model
    const mediaStream = this.mediaModel$.value;

    // Check if the media stream exists
    if (!mediaStream) {
      return false;
    }

    // Set the 'wantToStartRecord' flag to true
    this.isCoundownInProgressSubject.next(true);

    return true;
  }

  public async convertVideoLayerToDashAsync(
    scene: IScene,
    take: ITake,
    videoLayerId: string
  ) {
    if (!scene || !take || !videoLayerId) {
      throw new IllegalArgumentException(
        `Could not convert video layer to dash because on of the arguments is null`
      );
    }

    return new Promise<void>((resolve, reject) => {
      this.takeApiService
        .convertVideoLayersToDash$(
          this.project.id,
          scene.id,
          take.id,
          videoLayerId
        )
        .subscribe({
          next: (response) => {
            return resolve();
          },
          error: (error) => {
            console.error(`ERRROR ${error}`);
            return reject();
          },
        });
    });
  }

  /**
   * Updates the media stream asynchronously.
   *
   * This method stops the current media stream (if any) and obtains a new media stream from the MediaDevicesService.
   * The new media stream is then stored in the mediaModel$ subject.
   *
   * @returns {Promise<void>} A promise that resolves when the media stream is updated.
   */
  public async updateMediaStreamAsync(): Promise<void> {
    // Get the current media stream from the mediaModel$ subject
    let previousStream = this.mediaModel$.value;

    // Stop the current media stream, if it exists
    if (previousStream?.mediaStream) {
      previousStream.mediaStream.getTracks().forEach((track) => track?.stop());
    }

    // Get the new media stream from the MediaDevicesService
    let newMediaStream: MediaStream;
    newMediaStream = await this.mediaDevicesService.getMediaStreamAsync();

    // Create a new media model object with the user's ID and the new media stream
    const mediaModel: IMediaModel = {
      id: this.profileService.userPeer.id,
      mediaStream: newMediaStream,
      stagePositionId: this.currentStagePositionId,
    };

    // Update the mediaModel$ subject with the new media model
    if (this.mediaModel$.value) {
      // If the mediaModel$ subject already has a value, update its properties
      this.mediaModel$.value.id = mediaModel.id;
      this.mediaModel$.value.mediaStream = mediaModel.mediaStream;
    } else {
      // If the mediaModel$ subject doesn't have a value, emit the new media model
      this.mediaModel$.next(mediaModel);
    }
  }

  toggleMicMute() {
    const currentMediaStream = this.mediaModel$.value;
    if (!currentMediaStream) {
      return;
    }
    const audioTrack = currentMediaStream.mediaStream?.getAudioTracks();
    if (audioTrack && audioTrack.length > 0) {
      audioTrack.forEach((track) => (track.enabled = !track.enabled));
    }
  }

  public async replaceOrAddTakeAsync(
    project: IProject,
    scene: IScene,
    inTake: ITakeInDTO,
    userStreamId: string,
    baseDesignPath: string,
    waitForLocalVideos: boolean
  ) {
    const layout = scene.composition.layouts[0];
    const localTake = await this.takeConvertor.inToLocalAsync(
      inTake,
      null,
      layout,
      baseDesignPath,
      null,
      waitForLocalVideos,
      project.id,
      scene.id,
      scene.name,
      project.indexDBData,
      userStreamId
    );
    this.addOrReplaceTake(project, scene, localTake);
  }

  private addOrReplaceTake(project: IProject, scene: IScene, localTake: ITake) {
    return this.projectStoreService.replaceOrAddProjectTakes(
      project.id,
      scene.id,
      localTake
    );
  }

  private subscribeToMediaDeviceChanges() {
    this.mediaDevicesService.selectedCameraId$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((selectedCameraId: string) => {
        if (!selectedCameraId) {
          return;
        }
        this.updateMediaStreamAsync();
      });

    this.mediaDevicesService.selectedMicrophoneId$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((selectedMicrophoneId) => {
        if (!selectedMicrophoneId) {
          return;
        }

        this.updateMediaStreamAsync();
      });
  }

  /**
   * Will end the the countdown and actually start the recording
   * Caution: this should usually called from LiveSceneComponent after the countdown ended.
   */
  public async initiateRecordingAsync() {
    this.sendRecordingEventToMixpanel();

    await this.startRecordingAsync();
    this.recordingTimer = setInterval(() => {
      this.recordingTimeInSeconds++;
      // Convert seconds to milliseconds before sending to the timeformat pipe
      const recordingTimeInMilliseconds = this.recordingTimeInSeconds * 1000;

      this.recordingProgressService.RecordingTimeCounterValue =
        recordingTimeInMilliseconds;
    }, 1000);
    this.localRecorderService.startingToRecord$
      .pipe(
        filter((isStarted) => isStarted), // Wait for isStarted to be true
        take(1) // Take only the first emitted value after the condition is true
      )
      .subscribe(async (isStarted) => {
        if (!isStarted) {
          return;
        }

        try {
          this.isCoundownInProgressSubject.next(false);
          this.recordingProgressService.setRecordingInProgress(true);

          const proeprtiesToUpdate: ITakeUpdate<'startTime' | 'status'>[] = [
            {
              key: 'startTime',
              value: Date.now(),
            },
            {
              key: 'status',
              value: TakeStatusEnum.RECORDING,
            },
          ];

          await this.updateTakePropertyAsync(
            this.project,
            this.currentScene,
            this.currentTake.id,
            this.mediaModel$.value?.id,
            this.project.designGroup.design.basePath,
            proeprtiesToUpdate,
            false
          );
        } catch (error) {
          // TODO: Send an error that the studio can listen to and show a message
          console.error(
            `An error occurred while trying to get recording from index db! error: ${error}`
          );
        }
      });
  }

  private setTakeDynamicConfigs(take: ITake) {
    const { startTime, endTime } = this.takeConvertor.getStartAndEndTime(take);

    const dynamicData: IDynamicLottieData = {
      layout: this.currentScene.composition.layouts[0],
      basePath: this.project.designGroup.design.basePath,
      dynamicLottieChanges: take.copy.dynamicLottieChanges,
    };

    const configs = this.takeConvertor.getLottieVideoComposedConfigs(
      take.lottieLayers,
      take.videoLayers,
      dynamicData,
      startTime,
      endTime
    );
    take.lottieComposedConfigs = null;
    take.lottieComposedConfigs = configs;
    console.log(this.currentTake);
    console.log(this.currentTake);
  }

  private getLottieFromArtDirector(scene: IScene | ISceneInDTO) {
    return this.artDirector.loadedAssets.get(
      scene?.composition?.layouts[0]?.dynamicClientLayerId
    )?.content;
  }

  private async getLottieSettingsAsync(scene: IScene) {
    const lottieSettings = {
      lottieJsonPath: this.getLottieFromArtDirector(scene),
      lottiePositions: (
        await this.getCustomLayout(scene.composition.layouts[0].customLayoutId)
      ).stagePositions[0],
    };
    return lottieSettings;
  }

  public async getCustomLayout(customLayoutName: string) {
    if (!customLayoutName) {
      return null;
    }

    const customLayout = await lastValueFrom(
      this.projectApiService.customLayouts$.pipe(
        take(1),
        map((customLayouts) =>
          customLayouts.find((layout) => layout.name === customLayoutName)
        )
      )
    );

    return customLayout;
  }

  public async finishTakeAsync() {
    const isRecordInProgress =
      this.recordingProgressService.getRecordingInProgressValue();
    console.log(`Record ing progress?`, isRecordInProgress);
    if (!this.currentTake || !isRecordInProgress) {
      return;
    }
    this.recordingProgressService.setRecordingInProgress(false);

    const currentTime = Date.now();
    const duration = currentTime - this.currentTake.startTime;

    // Reset the recording timer
    if (this.recordingTimer) {
      clearInterval(this.recordingTimer);
      this.recordingTimeInSeconds = 0;
      this.recordingProgressService.RecordingTimeCounterValue =
        this.recordingTimeInSeconds;
    }

    await this.stopRecordForAllStreamsAsync(this.currentTake);

    const proeprtiesToUpdate: ITakeUpdate<'duration' | 'endTime' | 'status'>[] =
      [
        {
          key: 'endTime',
          value: currentTime,
        },
        {
          key: 'duration',
          value: duration,
        },
        {
          key: 'status',
          value: TakeStatusEnum.RECORDED,
        },
      ];

    await this.updateTakePropertyAsync(
      this.project,
      this.currentScene,
      this.currentTake.id,
      this.mediaModel$.value?.id,
      this.project.designGroup.design.basePath,
      proeprtiesToUpdate,
      true
    );

    // Inside updating take properties, it will build the lottie video configs,
    // So we will see the lottie video composed and not the live scene if staying
    // On the existed scene
    this.localRecorderService.removeBlobObjectFromLocal(
      this.currentTake.recordUniqueId
    );
  }

  private async stopRecordForAllStreamsAsync(take: ITake) {
    const mediaModel = this.mediaModel$.value;

    // Since stopRecording does not return a Promise, we don't await it here
    // but we must ensure stopRecording has completed before calling getRecording
    await this.localRecorderService.stopRecordingAsync(take.recordUniqueId);

    // Await the asynchronous getRecording method
    // const blob = await this.localRecorderService.getChunkAsync(recordId);

    try {
      // // Await the asynchronous getRecording method
      // const localRecordingIndexDB =
      //   await this.localRecorderService.getRecordingIndexDBObjectAsync();
      // if (localRecordingIndexDB) {
      // Since getRecording now directly returns the Blob, we adjust how we create the newVideoLayer
      //   const takeVideoLayer = take.videoLayers.find(
      //     (takeVideoLayer) => takeVideoLayer.id === mediaModel.id
      //   );
      //   if (takeVideoLayer) {
      //     takeVideoLayer.uploadPath = localRecordingIndexDB.content.uploadPath;
      //   }
      //   this.convertVideoLayerToDashAsync(
      //     this.currentScene,
      //     take,
      //     takeVideoLayer.id
      //   );
      // } else {
      //   console.error('No blob found');
      // }
    } catch (error) {
      console.error(
        `An error occurred while trying to get recording from index db! error: ${error}`
      );
    }
  }

  private async startRecordingAsync() {
    const mediaModel = this.mediaModel$.value;
    const recordUniqueId = this.currentTake.recordUniqueId;

    try {
      // Adding the video layer to the take at the server + to the local take
      await this.addVideoLayerToTakeAsync(
        this.project,
        this.currentScene,
        this.currentTake
      );

      this.localRecorderService.startRecordingAsync(
        mediaModel.mediaStream,
        recordUniqueId,
        this.currentTake
      );
      console.log(`Recording started for ${recordUniqueId}`);
    } catch (error) {
      console.error(`Error starting recording for ${recordUniqueId}:`, error);
      return; // TODO: handle
    }
  }

  public updateTakeSetupAsync(scene: IScene) {
    return new Promise<boolean>((resolve, reject) => {
      const proeprtiesToUpdate: ITakeUpdate<'copy'>[] = [
        {
          key: 'copy',
          value: scene.chosenTake.copy,
        },
      ];
      this.takeApiService
        .updateTake$(
          this.project.id,
          scene.id,
          scene.chosenTake.id,
          proeprtiesToUpdate
        )
        .subscribe({
          next: async (inTake) => {
            if (!inTake) {
              console.error(
                `Something strange happened while trying to update take.`
              );
              return resolve(false);
            }
            await this.updateTakePropertyAsync(
              this.project,
              this.currentScene,
              this.currentTake.id,
              this.mediaModel$.value?.id,
              this.project.designGroup.design.basePath,
              proeprtiesToUpdate,
              false
            );
          },
        });
    });
  }

  private sendRecordingEventToMixpanel() {
    const didRecordBeforeKey = this.profileService.didRecordLocalStorageKey;
    let didRecord: boolean = false;
    // the key might not be existed so it will fail to json parse.
    try {
      didRecord = JSON.parse(localStorage.getItem(didRecordBeforeKey));
    } catch (error) {}
    if (!didRecord) {
      // const sceneType: 'voiceover' | 'video' =
      //     this.currentCustomLayout.name === 'none' ? 'voiceover' : 'video';
      const data = {
        scene_type: 'video',
        recordStartedAt: new Date().toISOString(),
        projectCreatedAt: this.project.createdAt,
      };
      this.analyticsNotifierService.notifyEvent(`Record Take`, data);
      localStorage.setItem(didRecordBeforeKey, JSON.stringify(true));
    }
  }

  public set stagePositionId(stagePositionId: string) {
    this.currentStagePositionId = stagePositionId;
  }
}
