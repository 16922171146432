import { Component, Input, SimpleChanges } from '@angular/core';
import { IProject } from 'src/app/models/project-model';
import {
  EnhanceStatusEnum,
  IEditUpdate,
  IExportEditJob,
} from 'src/app/models/project/edit/edit-model';
import { EnhanceTypeEnum } from 'src/app/services/http-models/project/video-layer/video-layer-http-request-model';
import { EnhanceManagerService } from 'src/app/services/project/enhance/enhance-manager.service';
export interface ImagicToolConfig {
  title: string;
  description: string;
  icon: string;
  type: EnhanceTypeEnum;
}
@Component({
  selector: 'app-magic-tools',
  templateUrl: './magic-tools.component.html',
  styleUrl: './magic-tools.component.scss',
})
export class MagicToolsComponent {
  @Input({ required: true, alias: 'edit' })
  edit: IExportEditJob;

  @Input({ required: true, alias: 'project' })
  project: IProject;

  enhanceTypeEnum = EnhanceTypeEnum;
  tools: ImagicToolConfig[] = [
    {
      title: 'Voice enhancement',
      type: EnhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN,
      description:
        'Make your audio sound like it was recorded in a professional studio',
      icon: 'string',
    },
    {
      title: 'Blur background',
      type: EnhanceTypeEnum.VIDEO_SEGMENTATION,
      description:
        'Stay in the spotlight with a simple background blur as you speak',
      icon: 'string',
    },
  ];

  enhanceStatusEnum = EnhanceStatusEnum;

  constructor(public enhanceManager: EnhanceManagerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Detect changes to the 'edit' property
    if (changes.edit && changes.edit.currentValue) {
      console.log('Edit property changed:', changes.edit.currentValue);
      this.edit = changes.edit.currentValue;
    }
  }
  public onAudioCleanToggleChange(event: boolean, type: EnhanceTypeEnum): void {
    this.updateEnahanceToggleAsync(type);
    if (this.edit.toCleanAudio === true) {
      this.enhanceManager.enhanceAllTakesAsync(
        this.project,
        this.edit.id,
        type
      );
    }
  }

  public onSegmentToggleChange(event: boolean, type: EnhanceTypeEnum): void {
    this.updateEnahanceToggleAsync(type);
    if (this.edit.toSegmentVideo === true) {
      this.enhanceManager.enhanceAllTakesAsync(
        this.project,
        this.edit.id,
        type
      );
    }
  }

  public async updateEnahanceToggleAsync(type: EnhanceTypeEnum) {
    let proeprtiesToUpdate: IEditUpdate<'toCleanAudio' | 'toSegmentVideo'>[];
    try {
      if (type === EnhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN) {
        proeprtiesToUpdate = [
          {
            key: 'toCleanAudio',
            value: this.edit.toCleanAudio,
          },
        ];
      }
      if (type === EnhanceTypeEnum.VIDEO_SEGMENTATION) {
        proeprtiesToUpdate = [
          {
            key: 'toSegmentVideo',
            value: this.edit.toSegmentVideo,
          },
        ];
      }

      await this.enhanceManager.updateEnhanceTogglesAsync(
        this.project,
        this.edit.id,
        proeprtiesToUpdate
      );
    } catch (error) {
      console.error(
        `An error occurred while trying to update toCleanAudio toggle: ${error}`
      );
    }
  }
}
