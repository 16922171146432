import {
  IProjectInDTO,
  ProjectPropertiesToUpdateEnum,
  ScenePropertiesToUpdateEnum,
} from '../../../../../models/project-model';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../../../services/guards/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, take, throttleTime } from 'rxjs';
import { ProjectAuthApiService } from 'src/app/services/api/auth/project-auth-api.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { STUDIO_URL } from '../../../host/services/shared/constants/client/project/studio.routes';
import { SnackBarService } from 'src/app/services/utils/snack-bar.service';
import {
  CreativeStatusEnum,
  ProjectStatusEnum,
} from '../../../../../models/defines';

import { ProjectStoreService } from 'src/app/services/state-management/project/project-store.service';
import { IProject } from 'src/app/models/project-model';
import { DynamicItemType } from 'lottie-json-helper/lib/types';
import { MatDialog } from '@angular/material/dialog';
import { StartRecordDialogComponent } from 'src/app/components/dialogs/start-record-dialog/start-record-dialog.component';
import { ArtDirectorService } from 'src/app/services/art-director.service';
import { PaymentDialogComponent } from 'src/app/components/dialogs/plans-and-payments/payment-dialog/payment-dialog.component';
import { FeatureService } from 'src/app/services/feature-service.service';
import { PlanEnum, User } from 'src/app/models/user';
import { ProfileService } from 'src/app/services/show/profile.service';

const TIME_FOR_POLLING = 5000; // Polling interval in milliseconds

@Component({
  selector: 'project-planning',
  templateUrl: './project-planning.component.html',
  styleUrls: ['./project-planning.component.scss'],
})
export class ProjectPlanningComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  teammates = [
    { name: 'Isaac G', profilePic: 'assets/images/demo/Kath.jpg' },
    { name: 'Isaac G', profilePic: 'assets/images/demo/lego.jpg' },
    { name: 'Isaac G', profilePic: 'assets/images/demo/shira.jpeg' },
  ];
  loadingData = false;
  project: IProject;

  projectTitle: FormControl<string>;
  projectBriefFormControl: FormControl<string>;
  updateFormatInterval = null;

  generatingSnackbar: any;
  exportProductionModel: IProjectInDTO;
  baseCdnUrl: string;
  firstTimeGettingProject: Date;
  projectCreativeDone: boolean;
  projectCreativeGeneratingImages: boolean;
  isFirstTimePolling$ = new BehaviorSubject<boolean>(false);
  user: User;
  dialogRef: MatDialog;

  private onDestroy$: Subject<boolean> = new Subject();
  private pollingInterval: any;
  isPaymentDialogOpen = false;

  constructor(
    private changeDetectRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    public authentication: AuthenticationService,
    private config: ConfigurationService,
    private artDirector: ArtDirectorService,
    public projectAuthApiService: ProjectAuthApiService,
    private projectStoreService: ProjectStoreService,
    private dialog: MatDialog,
    private featureService: FeatureService,
    private profileService: ProfileService
  ) {
    this.baseCdnUrl = this.config.baseCdnUrl;
  }

  ngAfterViewInit(): void {
    const projectId = this.route.snapshot.params['id'];
    this.isFirstTimePolling$.next(true);

    if (projectId) {
      this.initProjectAsync(projectId);
      this.changeDetectRef.detectChanges();
    }
  }

  private async initProjectAsync(projectId: string) {
    try {
      this.loadingData = true;
      let project: IProject;
      try {
        project =
          await this.projectStoreService.setProjectSourceIfNotExistedAsync(
            projectId,
            false,
            null
          );
      } catch (error: any) {
        console.error(
          `An error occurred while trying to get project. error: ${error.message}`
        );
        this.snackBarService.openMessage(
          'WOOPS, An Error Occurred',
          null,
          10000,
          {
            panelClass: 'snackbar',
          }
        );
        return;
      }

      if (project?.creative?.status === CreativeStatusEnum.DONE) {
        this.openPaymentDialog();
      }

      if (project.creative.status === CreativeStatusEnum.STRUCTURE) {
        this.startPollingProjectCreativeStatus(project);
      } else {
        this.closeGeneratingSnackbar();
        // this.projectStoreService.updateProjectProperties(project.id, project);
      }

      await this.artDirector.preloadProjectAssetsAsync(project);
      this.project = project;
      this.projectTitle = new FormControl(project.title);
      this.projectCreativeDone =
        project.creative?.status === CreativeStatusEnum.DONE;
      this.projectBriefFormControl = new FormControl(
        project?.creative?.prompt ?? ''
      );
      console.log('production is:', this.project);
    } catch (e) {
      console.error(
        'Error occurred while trying to get production with ID:',
        e
      );
    } finally {
      this.loadingData = false;
    }
  }

  closeGeneratingSnackbar() {
    if (this.generatingSnackbar) {
      this.snackBarService.openMessage(
        'Your project is ready 🥳 ',
        null,
        4000,
        {
          panelClass: 'snackbar',
        }
      );
    }
  }

  ngOnInit(): void {
    this.user = this.profileService.user$.value;
  }

  ngOnDestroy(): void {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
    if (this.generatingSnackbar) {
      this.generatingSnackbar.dismiss();
    }
  }

  public startProduction() {
    if (!this.projectCreativeDone) {
      this.dialog.open(StartRecordDialogComponent, {
        width: '450px',
        height: '220px',
      });
    } else {
      this.router.navigate([
        'dashboard',
        { outlets: { panel: [STUDIO_URL, this.project.id] } },
      ]);
    }
  }

  public updateProjectName(event: string) {
    const projectId = this.project.id;
    const params = {
      projectId: projectId,
      value: event,
      key: ProjectPropertiesToUpdateEnum.PROJECT_TITLE,
    };
    this.projectAuthApiService
      .updateProjectProperty$(params)
      .pipe(throttleTime(500))
      .subscribe((updatedProject) => {
        if (!updatedProject) return;
        const propertiesToUpdate = {
          [params.key]: params.value,
        };
        this.projectStoreService.updateProjectProperties(
          projectId,
          propertiesToUpdate
        );
      });
  }

  public updateSceneTitle(event: any) {
    const params = {
      projectId: this.project.id,
      sceneId: event.sceneId,
      value: event.name,
      key: ScenePropertiesToUpdateEnum.SCENE_TITLE,
    };
    this.projectAuthApiService
      .updateSceneProperty$(params)
      .pipe(throttleTime(500))
      .subscribe((updatedScene) => {
        if (!updatedScene) return;
        this.projectStoreService.updateSceneProperty(
          params.projectId,
          params.sceneId,
          params.key,
          params.value
        );
      });
  }

  public openPaymentDialog() {
    if (this.isPaymentDialogOpen) return;

    this.featureService
      .hasFeatureAccess('AB_mustpay_free')
      .pipe(take(2))
      .subscribe({
        next: (hasAccess) => {
          const shouldShowPaymentsDialog =
            this.user.plan === PlanEnum.FREE && hasAccess === true;
 
          if (shouldShowPaymentsDialog && !this.isPaymentDialogOpen) {
            this.isPaymentDialogOpen = true;

            const dialogRef = this.dialog.open(PaymentDialogComponent, {
              disableClose: true,
              panelClass: 'payments-dialog',
              maxHeight: '90vh',
            });

            dialogRef.afterClosed().subscribe(() => {
              this.isPaymentDialogOpen = false; // Reset the flag when dialog closes
            });
          } else {
            this.isPaymentDialogOpen = false;
          }
        },
        error: (error) => {
          console.warn(`NO FEATURE FLAG DATA`);
          this.isPaymentDialogOpen = false;
        },
      });
  }

  private startPollingProjectCreativeStatus(project: IProject) {
    this.pollingInterval = setInterval(() => {
      this.projectAuthApiService.getProjectStatus$(project.id).subscribe({
        next: async (creativeStatus) => {
          if (!creativeStatus) return;

          if (
            creativeStatus !== project.creative.status ||
            creativeStatus === CreativeStatusEnum.STRUCTURE
          ) {
            switch (creativeStatus) {
              case CreativeStatusEnum.STRUCTURE:
                if (this.isFirstTimePolling$.value === true) {
                  this.updateProjectInStructureStatus(project);
                }
                break;
              case CreativeStatusEnum.GENERATING_IMAGES:
                // await this.initProjectAsync(project.id);
                this.project = project =
                  await this.projectStoreService.setCurrentProjectSourceAsync(
                    project.id,
                    false
                  );
                this.changeDetectRef.detectChanges();
                this.updateProjectInGeneratingImagesStatus(project);
                break;
              case CreativeStatusEnum.DONE:
                project =
                  await this.projectStoreService.setCurrentProjectSourceAsync(
                    project.id,
                    false
                  );
                this.projectCreativeDone = true;
                this.project.scenes = project.scenes;
                this.closeGeneratingSnackbar();
                clearInterval(this.pollingInterval);
                break;
            }
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    }, TIME_FOR_POLLING);
  }

  private updateProjectInStructureStatus(project: IProject) {
    this.isFirstTimePolling$.next(false);
    this.firstTimeGettingProject = new Date();

    this.generatingSnackbar = this.snackBarService.openMessage(
      'Generating Project - Creating Script...🚀',
      null,
      0,
      {
        panelClass: 'snackbar',
      }
    );

    if (project.scenes?.length > 1) {
      console.warn(
        'Project is in a structure creative status, removing all scenes after the first'
      );
      project.scenes = [project.scenes[0]];
    }
  }

  private updateProjectInGeneratingImagesStatus(project: IProject) {
    this.projectCreativeGeneratingImages = true;
    this.generatingSnackbar = this.snackBarService.openMessage(
      'Generating Project - Creating Images…. 📷',
      null,
      0,
      {
        panelClass: 'snackbar',
      }
    );
    // Ensure all scenes are displayed, including the first scene
    this.project.scenes = project.scenes;
    this.changeDetectRef.detectChanges();
  }

  private updateImages(project: IProject) {
    project.scenes.forEach((scene) => {
      let selectedTake = scene.takes.find(
        (take) => take.id === scene.selectedTakeId
      );
      const sceneToChangeImage = selectedTake.copy.dynamicLottieChanges.find(
        (dynamicLottieChange) =>
          dynamicLottieChange.type === DynamicItemType.IMAGE
      );
      if (sceneToChangeImage) {
        const sceneToChange = project.scenes.find(
          (scene) => scene.id === sceneToChangeImage.id
        );

        const compositionToChange = selectedTake.copy.dynamicLottieChanges.find(
          (dynamicLottieChanges) =>
            dynamicLottieChanges.type === sceneToChange.composition.type
        );
        if (sceneToChange && compositionToChange) {
          selectedTake = sceneToChange.takes[0];
        }
      }
    });
  }

  protected readonly ProjectStatus = ProjectStatusEnum;
}
