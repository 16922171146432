import { Injectable } from '@angular/core';
import { VideoLayerApiService } from '../../api/auth/projects/video-layer-api.service';
import { MissingArgumentsError } from '../../../models/errors/general.errors';

import { HttpErrorResponse } from '@angular/common/http';
import {
  EditUpdateableProperties,
  IEditUpdate,
} from 'src/app/models/project/edit/edit-model';
import { IProject } from 'src/app/models/project-model';
import { EditApiService } from 'src/app/services/api/auth/projects/edit-api.service';
import { BehaviorSubject } from 'rxjs';
import { EnhanceTypeEnum } from '../../http-models/project/video-layer/video-layer-http-request-model';
import { IScene } from 'src/app/models/project/scene-model';
@Injectable({
  providedIn: 'root',
})
export class EnhanceManagerService {
  loadingVoiceClean$ = new BehaviorSubject<boolean>(false);
  loadingSegmentation$ = new BehaviorSubject<boolean>(false);
  constructor(
    private videoLayerApi: VideoLayerApiService,
    private editApiService: EditApiService
  ) {}

  public audioCleanVideoLayersAsync(
    projectId: string,
    editId: string,
    sceneId: string,
    takeId: string,
    videoLayerIds: string | string[]
  ) {
    if (!projectId || !editId || !sceneId || !takeId || !videoLayerIds) {
      throw new MissingArgumentsError(
        `Could not enhance video layer(s) because one or more of the arguments is missing`
      );
    }
    return new Promise<void>((resolve, reject) => {
      this.videoLayerApi
        .audioCleanVideoLayers$(
          projectId,
          editId,
          sceneId,
          takeId,
          videoLayerIds
        )
        .subscribe({
          next: (res) => {
            return resolve();
          },
          error: (error) => {
            return reject();
          },
        });
    });
  }

  public segmentationVideoLayersAsync(
    projectId: string,
    editId: string,
    sceneId: string,
    takeId: string,
    videoLayerIds: string | string[]
  ) {
    if (!projectId || !editId || !sceneId || !takeId || !videoLayerIds) {
      throw new MissingArgumentsError(
        `Could not enhance video layer(s) because one or more of the arguments is missing`
      );
    }
    return new Promise<void>((resolve, reject) => {
      this.videoLayerApi
        .segmentationCleanVideoLayers$(
          projectId,
          editId,
          sceneId,
          takeId,
          videoLayerIds
        )
        .subscribe({
          next: (res) => {
            return resolve();
          },
          error: (error) => {
            return reject();
          },
        });
    });
  }

  public enhanceTakeAsync(
    projectId: string,
    editId: string,
    scene: IScene,
    enhanceType: EnhanceTypeEnum
  ) {
    if (
      !scene.chosenTake.videoLayers ||
      scene.chosenTake.videoLayers.length === 0
    ) {
      return;
    }

    if (enhanceType === EnhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN) {
      return this.audioCleanVideoLayersAsync(
        projectId,
        editId,
        scene.id,
        scene.chosenTake.id,
        scene.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
      );
    }

    if (enhanceType === EnhanceTypeEnum.VIDEO_SEGMENTATION) {
      return this.segmentationVideoLayersAsync(
        projectId,
        editId,
        scene.id,
        scene.chosenTake.id,
        scene.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
      );
    }
  }

  public enhanceAllTakesAsync(
    project: IProject,
    editId: string,
    enhanceType: EnhanceTypeEnum
  ) {
    project.scenes.forEach((scene) => {
      if (
        !scene.chosenTake.videoLayers ||
        scene.chosenTake.videoLayers.length === 0
      ) {
        return;
      }
      if (enhanceType === EnhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN) {
        return this.audioCleanVideoLayersAsync(
          project.id,
          editId,
          scene.id,
          scene.chosenTake.id,
          scene.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
        );
      }

      if (enhanceType === EnhanceTypeEnum.VIDEO_SEGMENTATION) {
        return this.segmentationVideoLayersAsync(
          project.id,
          editId,
          scene.id,
          scene.chosenTake.id,
          scene.chosenTake.videoLayers.map((videoLayer) => videoLayer.id)
        );
      }
    });
  }

  /**
   *
   * @param projectId
   * @param editId
   * @param propertiesToUpdate
   * @returns
   */
  public updateEnhanceTogglesAsync<K extends keyof EditUpdateableProperties>(
    project: IProject,
    editId: string,
    propertiesToUpdate: IEditUpdate<K>[]
  ) {
    if (!project || !editId || !propertiesToUpdate) {
      throw new MissingArgumentsError(
        `Could not update demo take because one of the arguments is null or undefined.`
      );
    }
    if (propertiesToUpdate.length === 0) {
      return;
    }

    return new Promise<boolean>((resolve, reject) => {
      this.editApiService
        .updateEdit$(project.id, editId, propertiesToUpdate)
        .subscribe({
          next: async (inEdit) => {
            if (!inEdit) {
              console.error(
                `Something strange happened while trying to update edit.`
              );
              return resolve(false);
            }

            return resolve(true);
          },
          error(err: HttpErrorResponse) {
            if (err.status === 500) {
            }
          },
        });
    });
  }
}
