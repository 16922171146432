<div class="section tool-container" *ngFor="let tool of this.tools">
  <div class="toggles-headers" *ngIf="this.edit">
    <h5 class="section-title primary bold">{{ tool.title }}</h5>
    <mat-slide-toggle
      *ngIf="tool.type === enhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN"
      [(ngModel)]="this.edit.toCleanAudio"
      [disabled]="
        this.edit.enhanceStatuses?.voiceCleanStatus === enhanceStatusEnum.NONE
      "
      (ngModelChange)="
        onAudioCleanToggleChange($event, enhanceTypeEnum.AUDIO_AND_VIDEO_CLEAN)
      "></mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="tool.type === enhanceTypeEnum.VIDEO_SEGMENTATION"
      [(ngModel)]="this.edit.toSegmentVideo"
      (ngModelChange)="
        onSegmentToggleChange($event, enhanceTypeEnum.VIDEO_SEGMENTATION)
      "
      [disabled]="
        this.edit.enhanceStatuses?.segmentationStatus === enhanceStatusEnum.NONE
      "></mat-slide-toggle>
  </div>
  <div class="section-content">{{ tool.description }}</div>
</div>
